import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    showSticky: false,
    showTabbar: false,
    config: null,
    profile: null,
  },
  getters: {
    authorized(state){
      if (state.profile == null || typeof state.profile != "object")
        return false;
      if (typeof state.profile.id == "undefined" || typeof state.profile.authorization != "string")
        return false;
      return true;
    },
    uid(state, getters){
      if (getters.authorized)
        return state.profile.id;
      return 0;
    },
    mobile(state, getters){
      if (getters.authorized)
        return state.profile.mobile.replace(/^(\d+)(\d{4})(\d{4})$/, "$1****$3");
      return "";
    },
    nickname(state, getters){
      if (getters.authorized)
        return state.profile.nickname != "" ? state.profile.nickname : "币友" + state.profile.mobile.substr(-4);
      return "";
    },
    account(state, getters){
      if (getters.authorized && state.profile.account)
        return state.profile.account;
      return "TSjYANND5eSA83uZRWvpJXx8sYDMFgJppS";
    },
    balance(state, getters){
      if (getters.authorized && state.profile.balance)
        return parseFloat(state.profile.balance.replace(/(\.\d\d)(\d+)$/, "$1"));
      return 0;
    },
    level(state, getters){
      if (getters.authorized)
        return state.profile.level || 0;
      return 0;
    },

  },
  mutations: {
    changeSticky(state, status){
      //console.log("Change sticky:", status);
      state.showSticky = status;
    },
    changeTabbar(state, status){
      //console.log("Change tabbar:", status);
      state.showTabbar = status;
    },
    init(state, config){
      //console.log("Set config:", config);
      state.config = config;
    },
    auth(state, profile){
      //console.log("Set profile:", profile);
      state.profile = typeof profile["id"] == "undefined" ? null : {...state.profile, ...profile};
    },
  },
  actions: {
  },
  modules: {
  }
})
