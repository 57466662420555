import { createRouter, createWebHashHistory } from 'vue-router'
import store from './store.js'
import NotFound from '@/views/404.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/quantize',
    name: 'Quantize',
    component: () => import('@/views/TheQuantize.vue'),
  },
  {
    path: '/entrust',
    name: 'Entrust',
    component: () => import('@/views/TheEntrust.vue'),
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('@/views/Invite.vue'),
  },

  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/My.vue'),
  },
  {
    path: '/my/profile',
    name: 'MyProfile',
    component: () => import('@/views/MyProfile.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my/wallet',
    name: 'MyWallet',
    component: () => import('@/views/MyWallet.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my/:action(deposit|withdrawal)+',
    name: 'MyWalletCash',
    component: () => import('@/views/MyWalletCash.vue'),
    props: route => ({ action: route.params.action }),
    meta: {requiresAuth: true}
  },
  {
    path: '/my/exchange',
    name: 'MyExchange',
    component: () => import('@/views/MyExchange.vue'),
    meta: {requiresAuth: true}
  },  
  {
    path: '/my/strategy/:action(create|modify|adjust|apply)+',
    name: 'MyExchangeStrategy',
    component: () => import('@/views/MyExchangeStrategy.vue'),
    props: route => ({ action: route.params.action, id: route.query.id }),
    meta: {requiresAuth: true}
  },  
  {
    path: '/my/plan',
    name: 'MyExchangePlan',
    component: () => import('@/views/MyExchangePlan.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my/order',
    name: 'MyOrder',
    component: () => import('@/views/MyOrder.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my/invite',
    name: 'MyInvite',
    component: () => import('@/views/MyInvite.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my/recent',
    name: 'MyRecent',
    component: () => import('@/views/MyRecent.vue'),
    meta: {requiresAuth: true}
  },  
  {
    path: '/vip',
    name: 'MyVIP',
    component: () => import('@/views/MyVIP.vue'),
  },
  {
    path: '/:action(login|logout|register|changepassword|invite)+/:code?',
    name: 'Passport',
    component: () => import('@/views/Passport.vue'),
    props: route => ({ action: route.params.action, code: route.params.code, redirect: route.query.redirect }),
  },

  {
    path: '/:page(feature|intro|guide|help|about|contact|terms)+',
    name: 'Page',
    component: () => import('@/views/Page.vue'),
    props: true,
  },
  {
    path: '/:path*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !store.getters.authorized) {
    console.warn("需要登录授权");
    return {
      path: '/login',
      query: {redirect: to.fullPath},
    }
  }
  store.commit("changeTabbar", "Passport|Strategy".indexOf(to.name) == -1);
  return true;
});

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
});

export default router
