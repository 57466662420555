<template>
  <div id="container">
    <router-view v-slot="{Component, route}">
      <transition :name="route.meta.transition ? route.meta.transition : 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <van-sticky offset-bottom="120" position="bottom" v-show="$store.state.showSticky">
    <div id="service" @click="$router.push('/contact')"><van-icon name="service" size="24" color="#FFF"></van-icon></div>
  </van-sticky>
  <van-tabbar v-model="active" v-show="$store.state.showTabbar" placeholder route>
    <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
    <van-tabbar-item icon="diamond-o" to="/feature">功能</van-tabbar-item>
    <van-tabbar-item icon="medal-o" to="/vip">会员</van-tabbar-item>
    <van-tabbar-item icon="contact" to="/my">我的</van-tabbar-item>
  </van-tabbar>
</template>

<style>
html, body, #app{
  height: 100%;
  margin: 0; 
  padding: 0;
  background: #F9F9F9;
  color: #666;
  font-family: robotoregular,PingFang SC,Hiragino Sans GB,Heiti SC,Microsoft YaHei,WenQuanYi Micro Hei,Helvetica,Arial,monospace,serif;
  text-align: left;
  overflow: hidden;
}


#container {
  position: relative;
  height: 100%;
  display: block;
  overflow: auto;
}

#service {
  float: right;
  width: 45px;
  height: 36px;
  background: #000;
  border-radius: 18px 0 0 18px;
  line-height: 45px;
  text-align: center;
  overflow: hidden;
  opacity: 0.4;
}

.van-nav-bar .van-icon {
  color: #39F !important;
  text-shadow: 1px 0px 1px #FFF;
}

.van-cell__left-icon {
  margin-right: 10px !important;
}
.van-submit-bar__bar {
  font-size: 12px !important;
}
</style>

<script>
import {ref} from 'vue';

export default {
  setup() {
    const active = ref('home');
    return {active};
  }

};
</script>
