<template>
  <BaseLayout>
    <van-empty image="error" description="页面不存在" />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
export default {
  components: {BaseLayout},
}
</script>
