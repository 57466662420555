<template>
  <div class="container">
    
    <van-sticky @change="state.isTop = !state.isTop" v-show="height != 0">
      <van-nav-bar class="navbar" :class="{notTop: !state.isTop}" :title="state.isTop ? '' : title" :border="false" @click-left="onClickLeft" @click-right="$emit('click-more')">
        <template #left>
          <div v-show="!noback"><slot name="back"><van-icon name="arrow-left" size="large"></van-icon></slot></div>
        </template>
        <template #right>
          <slot name="more"></slot>
        </template>
      </van-nav-bar>
    </van-sticky>

    <div class="page" :class="{notTop: !state.isTop}" :style="{background:bg}">
      <transition name="van-slide-down">
        <van-row justify="space-between" align="center" class="head" :style="{color:fg}" v-show="state.isTop && !notitle && height != 0">
          <van-col span="16">
            <div class="title" :style="{height: height, color:fg}" @click="$emit('click-title')">
              {{title}}
              <div class="subtitle">{{subtitle}}</div>
            </div>
          </van-col>
          <van-col span="8">
            <div class="extra"><slot name="extra"></slot></div>
          </van-col>
        </van-row>
      </transition>
      <slot></slot>
    </div>

  </div>
</template>

<style scoped>
.navbar {
  background: transparent;
}
.navbar.notTop {
  background: #FCFCFC;
  border-bottom: solid 1px #F6F6F6;
}

.page {
  position: relative;
  top: -45px;
  padding-top: 45px;
  min-height: 600px;
  display: block;
}
.page.notTop {
  top: 0;
  margin-bottom: 50px;
}
.head {
  margin-bottom: 20px;
  background: transparent;
  color: #000;
}
.head .title {
  min-height: 40px;
  padding: 0px 0px 0px 20px;
  font-size: 28px;
  font-weight: 600;  
  /* text-shadow: 0px 0px 1px #000; */
  text-align: left;
}
.head .subtitle {
  font-size: 14px;  
  font-weight: 400;
  line-height: 28px;
  opacity: 0.8;  
}
.head .extra {
  float: right;
}
</style>

<script>
import { reactive } from 'vue'

export default {
  props: {
    notitle: {
      type: Boolean,
      default: false,
    },
    title: String,
    subtitle: String,
    height: String,
    bg: String,
    fg: String,
    noback: {
      type: Boolean,
      default: false,
    },
  },

  setup(){
    const state = reactive({
      isTop: true,
    });

    return {
      state
    };
  },

  methods: {
    onClickLeft(){
      if (typeof this.$slots.back == "function")
        return this.$emit("click-back");
      console.log("<<< BACK");
      return this.$router.back();
    }
  }
}
</script>
