import axios from 'axios'
import qs from 'qs'
import store from './store.js'
import router from './router.js'
import {Toast, Notify, Dialog} from 'vant'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

axios.interceptors.request.use((config) => {
  config.method != "get" && Toast({type: "loading", message: "请稍候 ...", duration: 10000, forbidClick: true});
  
  config.headers = Object.assign(config.headers, {"Content-Type": "application/x-www-form-urlencoded", "x-requested-with": "xmlhttprequest"});
  if (store.state.profile && store.state.profile.authorization)
    config.headers['token'] = store.state.profile.authorization;
  
  config.paramsSerializer = function (params) {
    return qs.stringify(params, {arrayFormat: "repeat"});
  };
  config.transformRequest = [function (data, headers) {
    return qs.stringify(data,  {arrayFormat: "brackets"});
  }];

  config.url += (config.url.indexOf("?") > -1 ? "&" : "?") + "rnd=" + Math.random();

  return config;
});

axios.interceptors.response.use((response) => {

  Toast.clear();

  if (response.status !== 200 || typeof response.data.rtn != "number")
    return Notify({type: "danger", message: "服务异常，请重试！"});

  if (response.data.rtn == 999){
    Toast("授权已失效");
    return router.push({
      path: '/login',
      query: {redirect: router.currentRoute.fullPath},
    });
  }

  if (response.data.rtn == 998){
    axios.get("/user/codesend");
    return Dialog.confirm({
      title: response.data.msg,
      message: "<div style='margin:20px 5px;line-height:14px;'><input id='vcode' value='' style='width:90%;font-size:24px;text-align:center;' placeholder='请输入验证码'/><p>验证码已发送至当前账号绑定的手机</p></div>",
      theme: "round-button",
      allowHtml: true,
      beforeClose: (action) => new Promise((resolve) => {
        if (action == "cancel") return resolve(true);
        axios.get("/user/codecheck?code=" + document.querySelector("#vcode").value).then(() => resolve(true)).catch(() => resolve(false));
      }),
    });
  }

  if (response.data.rtn != 0){
    Notify({type: "danger", message: response.data.msg, duration: 8000});
    return Promise.reject(response.data);
  }
  response.data.msg.length > 2 && Toast(response.data.msg);
  return Promise.resolve(response.data.data);

}, error => {

  Notify({type: "danger", message: "网络请求错误，请重试！",});
  console.error("网络错误：", error);

});

export default axios
