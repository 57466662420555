import { createApp } from 'vue'
import App from './App.vue'
import store from './store.js'
import router from './router.js'
import http from './http.js'
import vant from 'vant'
import 'vant/lib/index.css'
import animated from 'animate.css'
import './main.css'
import useClipboard from 'vue-clipboard3'

const app = createApp(App)
app.use(store).use(router).use(vant).use(animated).mount('#app')

app.config.globalProperties.$http = http

function init(fn){
  console.log("init");
  http.get("/config").then((data) => store.commit("init", data));
  store.getters.authorized && http.get("/user/profile").then((data) => {
    store.commit("auth", data);
    typeof fn == "function" && fn();
  });
}
app.config.globalProperties.$init = init;

async function copy(text, notice = true) {
  await useClipboard().toClipboard(text);
  notice && this.$toast("已复制到剪贴板");
}
app.config.globalProperties.$copy = copy;

init();
